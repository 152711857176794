import Link from "next/link";

export default function FourOhFour() {
  return (
    <div className="md:p-4 flex justify-center">
      <main className="flex flex-col items-center py-8 px-4 bg-slate-50 border border-slate-500 drop-shadow-2xl md:w-3/4 text-2xl md:text-5xl">
        <h1>404 - Page Not Found ☹️</h1>
        <Link className="text-blue-300 hover:text-blue-500 underline" href="/">
          <p>Go back home</p>
        </Link>
      </main>
    </div>
  );
}
